import { Index, createEffect, createSignal, onCleanup, type ComponentProps } from "solid-js";
import ExampleBadJPG from "~/assets/example-bad.jpg";
import ExampleGoodJPG from "~/assets/example-good.jpg";
import { Button } from "~/components/button";
import { Icon } from "~/components/icon";
import { Link } from "~/components/link";
import { useTranslation } from "~/i18n";
import { useSession } from "~/session";
import { cx } from "~/utils";

const Tips = () => {
  const [session] = useSession();
  const { t } = useTranslation(session.locale);

  const tips = [
    t("howto.modal.list.1"), //
    t("howto.modal.list.2"),
    t("howto.modal.list.3"),
    t("howto.modal.list.4"),
  ] as const;

  return (
    <ul class="flex list-none flex-col gap-1 text-left lg:gap-2">
      <Index each={tips}>
        {(entry, idx) => (
          <li class="flex gap-4 bg-blue-20 p-2 lg:p-6">
            <div class="flex size-6 shrink-0 items-center justify-center rounded-lg bg-blue-50">
              <span class="font-semibold text-blue">{idx + 1}</span>
            </div>
            {entry()}
          </li>
        )}
      </Index>
    </ul>
  );
};

const Examples = () => {
  const examples = [
    { bg: "bg-blue", icon: "check", src: ExampleGoodJPG.src },
    { bg: "bg-coral", icon: "close", src: ExampleBadJPG.src },
  ] as const;

  return (
    <div class="flex w-full gap-2 md:justify-center">
      <Index each={examples}>
        {(item) => (
          <div class="relative">
            <img src={item().src} class="aspect-square w-full rounded-2xl object-cover lg:w-52" />
            <div class="absolute bottom-3 flex w-full items-center justify-center">
              <Icon name={item().icon} class={cx(["rounded-full text-white", item().bg])} />
            </div>
          </div>
        )}
      </Index>
    </div>
  );
};

export const HowToModal = (props: ComponentProps<"button">) => {
  const [session] = useSession();
  const { T } = useTranslation(session.locale);
  const [isOpen, setIsOpen] = createSignal(false);

  let content!: HTMLDivElement;
  let dialog!: HTMLDialogElement;

  const handleOpen = () => {
    window.analytics?.track("Help Selecting Picture Modal Opened");
    setIsOpen(true);
  };

  const handleClose = () => {
    window.analytics?.track("Help Selecting Picture Modal Closed");
    setIsOpen(false);
  };

  const onEscape = (ev: KeyboardEvent) => {
    if (ev.key === "Escape") {
      handleClose();
    }
  };

  createEffect(() => {
    if (isOpen()) {
      dialog.showModal();
      window.addEventListener("keydown", onEscape);
    } else {
      dialog.close();
      window.removeEventListener("keydown", onEscape);
    }
  });

  onCleanup(() => {
    setIsOpen(false);
    window.removeEventListener("keydown", onEscape);
  });

  return (
    <>
      <dialog
        ref={dialog}
        class="fixed inset-0 z-10 m-0 size-full max-h-screen max-w-[100vw] bg-transparent backdrop:bg-black/50"
        onClick={(ev) => {
          if (ev.target === content) {
            handleClose();
          }
        }}
      >
        <div ref={content} class="flex size-full flex-col items-center justify-center">
          <div class="m-auto w-[min(calc(100vw-16px),680px)]">
            <div class="mx-auto block w-min translate-y-1/2">
              <Button before={<Icon name="close" />} onClick={handleClose} isCircle />
            </div>
            <div class="rounded-2xl bg-white p-4 pt-8 shadow-sm lg:p-6 lg:pt-16">
              <div class="flex flex-col items-center gap-4 lg:gap-6">
                <T term="howto.modal.title" as="h2" class="text-center text-lg [&_em]:text-blue" />
                <Examples />
                <Tips />
              </div>
            </div>
          </div>
        </div>
      </dialog>
      <Link as="button" before={<Icon name="info" />} onClick={handleOpen} {...props}>
        <T term="home.howto" />
      </Link>
    </>
  );
};
